import React, {useEffect} from 'react';
import Header from '../Templates/Shared/Header';
import Helmet from 'react-helmet';
import styles from '../Templates/Styles/staticpage.module.scss';
import aboutPageData from '../../data/staticPages/aboutusPage.json';
import htmr from 'htmr';
import {Nav, Tab} from 'react-bootstrap';
import EmployeeTabComponent from '../Templates/Shared/EmployeeTabComponent';
import Footer from '../Templates/Shared/Footer';
import SEO from '../Templates/SEO';
import {getCanonicalURLs, migratedPartners, handleScroll} from
  '../Templates/Shared/StaticPageService';
import {getParameterByName} from '../Templates/Shared/Utils';
import cookie from 'react-cookies';

const About = () => {
  const locale = 'en';
  const canonicals = getCanonicalURLs(aboutPageData.seo.canonicalurls);

  useEffect(() => {
    const utmSource = cookie.load(
        'utm_source') || getParameterByName('utm_source');
    const isMigratedPartner = migratedPartners.includes(utmSource);
    const id = document.getElementById('signupcta');
    if (isMigratedPartner) {
      if (id) {
        id.href = `https://learning.upskillist.com/${utmSource}`;
      }
    } else {
      if (id) {
        if (utmSource?.toLowerCase()?.startsWith('sc') ||
          utmSource?.toLowerCase()?.startsWith('stack')) {
          id.href = `https://learning.upskillist.com/stackcommerce`;
        } else {
          id.href = `https://learning.upskillist.com/?utm_source=${utmSource}&utm_medium=partners`;
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <SEO
        locale={locale}
        title={aboutPageData.seo.title}
        description={aboutPageData.seo.description}
        image={''}
        canonicalUrls={canonicals}
        languageCanonicals={aboutPageData.languageCanonicals}
        schemaMarkup={null}
      />
      <Helmet>
        <script>
          window.dataLayer=window.dataLayer||[];
        </script>
      </Helmet>
      <Header locale={locale} />
      <main className={`about container ${styles.staticPage}`}>
        {aboutPageData.sections.map((section) => {
          let content = '<div></div>';
          if (section.pagesection) {
            content = section.pagesection.content;
          }
          if (section.teamsection) {
            const defaultTab =
              section.teamsection?.group?.[0]?.tablabel?.toLowerCase();
            return (
              <section className={styles.people}>
                <div className="team-header">
                  <h3>{section.teamsection.heading}</h3>
                </div>
                <Tab.Container defaultActiveKey={defaultTab}>
                  <div className={styles.content}>
                    <div className={styles.tabHeader}>
                      <Nav variant="tabs" as="ul">
                        {section.teamsection.group.map((group, index) => (
                          <Nav.Item as="li" key={index}>
                            <Nav.Link
                              eventKey={group.tablabel?.toLowerCase()}>
                              <span>{group.tablabel}</span>
                            </Nav.Link>
                          </Nav.Item >
                        ))}
                      </Nav>
                      <p className={styles.faint}></p>
                    </div>
                    <Tab.Content>
                      {section.teamsection.group.map((group, index) => (
                        <Tab.Pane key={index}
                          eventKey={group.tablabel?.toLowerCase()}>
                          <EmployeeTabComponent
                            showMoreText={section.teamsection.showmoretext}
                            members={group.members} />
                        </Tab.Pane>),
                      )}
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </section>
            );
          }
          return (htmr(content));
        })}
      </main>
      <Footer />
    </div>
  );
};

export default About;
