import React, {useState} from 'react';
import PropTypes from 'prop-types';
import htmr from 'htmr';
import styles from '../Styles/staticpage.module.scss';

const EmployeeTabComponent = ({members, showMoreText}) => {
  const [showEmployeePopup, triggerEmployeePopup] = useState(false);

  /**
   *
   *
   * @param {*} name
   */
  const openEmployeePopup = (name) => {
    triggerEmployeePopup((prevState) => (
      {[name]: !prevState[name]}
    ));
  };

  return (
    <>
      <div className={styles.employeeDivWrapper}>
        {members.map((member, index) => (
          <React.Fragment key={index}>
            <div className={styles.employeeDiv}
              onClick={() => openEmployeePopup(member.membername)}>
              <div className={styles.imgWrap}>
                <img src={member.memberimage.href}
                  alt={member.memberimage.itle} />
              </div>
              <div className={styles.employeeContentWrap}>
                <h4>{member.membername}</h4>
                <p className={styles.dirTitle}>{member.membertitle}</p>
                <a className={styles.show}>{showMoreText}</a>
              </div>
            </div>
            {showEmployeePopup[member.membername] &&
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <div className={styles.popupTopSection}>
              <img className={styles.closePopup}
                onClick={() => triggerEmployeePopup(member.membername)}
                src="/images/about/closepopup.svg" />
              <div className={styles.employeeImg}>
                <img src={member.memberimage.href}
                  alt={member.memberimage.itle}
                />
              </div>
              <div className={styles.employeeName}>
                <h4>{member.membername}</h4>
                <p>
                  {member.membertitle}
                </p>
              </div>
            </div>
            <hr />
            <div className={styles.popupBottomSection}>
              {htmr(member.memberbio)}
            </div>
          </div>
        </div>}
          </React.Fragment>
        ))}
      </div>

    </>
  );
};

export default EmployeeTabComponent;

EmployeeTabComponent.propTypes = {
  members: PropTypes.array,
  showMoreText: PropTypes.string,
};
